<!-- =========================================================================================
  File Name: ClientOrdersHistory.vue
  Description: Подробная история заказов и визитов посетителя
========================================================================================== -->
<template>
  <vx-card title="История посетителя">
    <vs-table :data="data" :sst="true" @change-page="handleChangePage" stripe>
      <template slot="thead">
        <vs-th>ID</vs-th>
        <vs-th>Название</vs-th>
        <vs-th>Время начала</vs-th>
        <vs-th>Время завершения</vs-th>
        <vs-th>Продолжительность (мин.)</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="item"
          :key="index"
          v-for="(item, index) in data"
          ref="trs"
        >
          <vs-td><span class="font-bold">{{ item.id }}</span></vs-td>
          <vs-td>{{ item.name }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(item.start_time) }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(item.end_time) }}</vs-td>
          <vs-td>
            {{
              $helpers.getTimeIntervalInMins(item.end_time - item.start_time)
            }}
          </vs-td>

          <template slot="expand">
            <div class="w-full border-dashed">
              <customer-visit-details :id="item.id" />
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>

    <vs-pagination
      :total="getTotalPages"
      v-model="p"
      class="mt-4"
      :maxItems="1"
      :sizeArray="1"
      prev-icon="arrow_back"
      next-icon="arrow_forward"
    />
  </vx-card>
</template>

<script>
import BaseTable from "@/views/components/BaseTable.vue";
import CustomerVisitDetails from "@/views/apps/CustomerVisitDetails.vue";

export default {
  mixins: [BaseTable],
  components: {
    CustomerVisitDetails
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    async getData() {
      await this.$http({
        method: "get",
        url: `/udata/users/get_customer_visits/${this.id}/.json`,
        withCredentials: true
      })
        .then(response => {
          if (!response.data.error) {
            this.data = Object.values(response.data.items);
          } else {
            this.$vs.notify({
              title: "Ошибка",
              text: response.data.error,
              color: "warning",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Ошибка",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    }
  },
  created() {
    this.getData();
  }
};
</script>
