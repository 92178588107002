<!-- =========================================================================================
  File Name: CustomerVisitDetails.vue
  Description: Подробная информация о визите посетителя
========================================================================================== -->
<template>
  <vx-card>
    <vs-table
      v-if="data.time_order"
      :data="[data.time_order]"
      :sst="true"
      stripe
    >
      <template slot="header">
        <h4>Заказ-время</h4>
      </template>
      <template slot="thead">
        <vs-th>ID</vs-th>
        <vs-th>Название</vs-th>
        <vs-th>Время оформления</vs-th>
        <vs-th>Время обновления</vs-th>
        <vs-th>Время закрытия</vs-th>
        <vs-th>Статус</vs-th>
        <vs-th>Смена</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="order"
          :key="index"
          v-for="(order, index) in data"
          ref="trs"
        >
          <vs-td
            ><span class="font-bold">{{ order.id }}</span></vs-td
          >
          <vs-td>{{ order.name }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.order_date) }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.last_update_time) }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.order_closed_date) }}</vs-td>
          <vs-td>{{ order.order_status }}</vs-td>
          <vs-td>{{ order.order_shift_id }}</vs-td>

          <template slot="expand">
            <div class="flex flex-col w-full p-2 border-dotted border-primary">
              <div>
                <div>Пропущена оплата? {{ order.missed_payment }}</div>
                Стоимость заказа без скидки: {{ order.total_original_price }} 
                | Размер скидки: {{ order.order_discount_value }} 
                | Скидка до стопчека: {{ order.timeorder_limit_discount_value }} 
                | Стоимость заказа:{{ order.total_price }} 
                | Скидка: {{ order.order_discount_id }}
              </div>
              <vs-list>
                <vs-list-header title="Параметры применения скидок" />
                <vs-list-item title="Тариф">
                  {{ order.tariff_object_id }}
                </vs-list-item>
                <vs-list-item title="Тип клиента">
                  {{ order.client_type_id }}
                </vs-list-item>
              </vs-list>
              <vs-divider color="warning" />
              <vs-list>
                <vs-list-header title="Тарифы" />
                <vs-list-item
                  v-for="order_item in order.order_items"
                  :key="order_item.id"
                  :title="order_item.id.toString()"
                  :subtitle="order_item.name"
                >
                  Время:
                  {{ $helpers.formatDateTime(order_item.start_time) }} -
                  {{ $helpers.formatDateTime(order_item.end_time) }}
                  <div v-if="order_item.event_id">
                    <vs-divider />
                    Мероприятие: {{ order_item.event_id[0].name + '(id' + order_item.event_id[0].id + ')' }} |
                    Билет: {{ order_item.tariff_ticket }}
                  </div>
                  <vs-divider />
                  Скидка на наименование: {{ order_item.item_discount_id }} |
                  (<span class="line-through">
                    {{ order_item.item_actual_price }}
                  </span>)
                   {{ order_item.item_actual_price }} руб. x
                  {{ order_item.item_amount }} мин. =
                  {{ order_item.item_total_price }} руб.
                </vs-list-item>
              </vs-list>
              <vs-divider color="warning" />
              <vs-list>
                <vs-list-header title="Оплаты" />
                <vs-list-item
                  v-for="order_payment in order.order_payments"
                  :key="order_payment.id"
                  :title="order_payment.id.toString()"
                  :subtitle="order_payment.name"
                >
                  ID: {{ order_payment.id }} | Сумма:
                  {{ order_payment.payment_amount }}
                </vs-list-item>
              </vs-list>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider />

    <vs-table v-if="data.orders" :data="data.orders" :sst="true" stripe>
      <template slot="header">
        <h4>Заказы</h4>
      </template>
      <template slot="thead">
        <vs-th>ID</vs-th>
        <vs-th>Название</vs-th>
        <vs-th>Время оформления</vs-th>
        <vs-th>Время обновления</vs-th>
        <vs-th>Время закрытия</vs-th>
        <vs-th>Статус</vs-th>
        <vs-th>Смена</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="order"
          :key="index"
          v-for="(order, index) in data"
          ref="trs"
        >
          <vs-td
            ><span class="font-bold">{{ order.id }}</span></vs-td
          >
          <vs-td>{{ order.name }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.order_date) }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.last_update_time) }}</vs-td>
          <vs-td>{{ $helpers.formatDateTime(order.order_closed_date) }}</vs-td>
          <vs-td>{{ order.order_status }}</vs-td>
          <vs-td>{{ order.order_shift_id }}</vs-td>

          <template slot="expand">
            <div class="flex flex-col w-full p-2 border-dotted border-primary">
              <div class="con-userx flex items-center justify-start">
                <div>
                  <div>Пропущена оплата? {{ order.missed_payment }}</div>
                  Стоимость заказа без скидки: {{ order.total_original_price }} 
                  | Размер скидки: {{ order.order_discount_value }} 
                  | Стоимость заказа:{{ order.total_price }} 
                  | Скидка: {{ order.order_discount_id }}
                </div>
              </div>
              <vs-list>
                <vs-list-header title="Параметры применения скидок" />
                <vs-list-item title="Тариф">
                  {{ order.tariff_object_id }}
                </vs-list-item>
                <vs-list-item title="Тип клиента">
                  {{ order.client_type_id }}
                </vs-list-item>
              </vs-list>
              <vs-divider color="warning" />
              <vs-list>
                <vs-list-header title="Товары" />
                <vs-list-item
                  v-for="order_item in order.order_items"
                  :key="order_item.id"
                  :title="order_item.id.toString()"
                  :subtitle="order_item.name"
                >
                  Скидка на наименование: {{ order_item.item_discount_id }} |
                  <span class="line-through">
                    {{ order_item.item_price }}
                  </span>
                  {{ order_item.item_actual_price }} руб. x
                  {{ order_item.item_amount }} шт. =
                  {{ order_item.item_total_price }} руб.
                  <vs-divider />
                  Билеты: {{ order_item.ticket_ids }}
                </vs-list-item>
              </vs-list>
              <vs-divider color="warning" />
              <vs-list>
                <vs-list-header title="Оплаты" />
                <vs-list-item
                  v-for="order_payment in order.order_payments"
                  :key="order_payment.id"
                  :title="order_payment.id.toString()"
                  :subtitle="order_payment.name"
                >
                  Сумма: {{ order_payment.payment_amount }} | Время:
                  {{ $helpers.formatDateTime(order_payment.payment_time) }}
                  <vs-divider />
                  Зафиксирована? {{ order_payment.fixed }} | Смена:
                  {{ order_payment.payment_shift }}
                </vs-list-item>
              </vs-list>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import BaseTable from "@/views/components/BaseTable.vue";

export default {
  mixins: [BaseTable],
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    async getData() {
      await this.$http({
        method: "get",
        url: `/udata/users/get_visit_details/${this.id}/.json`,
        withCredentials: true
      })
        .then(response => {
          if (!response.data.error) {
            this.data = response.data;
          } else {
            this.$vs.notify({
              title: "Ошибка",
              text: response.data.error,
              color: "warning",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Ошибка",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    }
  },
  created() {
    this.getData();
  }
};
</script>
